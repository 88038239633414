.unit-title-v1 {
	h1 {
		font-family: var(--hero-header-font-family);
		font-size: 2.5rem;

		@media (max-width: 992px) {
			font-size: 1.6rem;
		}
	}

	.stars {
		color: var(--color-stars);
		font-size: var(--font-size-md);
		vertical-align: top;
	}
}