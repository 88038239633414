.static-v1 {
	margin-top: var(--page-margin-top);

	.embed-container {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		max-width: 100%;
		margin-top: 40px;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

		}
	}


	.inner-banner {
		&.anreise {
			background-image: url(RESOURCE/img/banner-anreise.webp);
		}

		&.fragen {
			background-image: url(RESOURCE/img/banner-fragen.webp);
		}

		&.reisen {
			background-image: url(RESOURCE/img/banner-reisen.webp);
		}

		&.vermieter {
			background-image: url(RESOURCE/img/banner-team-inselzeit.webp);
		}

		&.strand {
			background-image: url(RESOURCE/img/banner-strand.webp);
		}

		&.natur {
			background-image: url(RESOURCE/img/banner-nature.webp);
		}

		&.haus {
			background-image: url(RESOURCE/img/banner-haus.webp);
		}

		&.nachh {
			background-image: url(RESOURCE/img/banner-nachh.webp);
		}

		&.inseldor {
			background-image: url(RESOURCE/img/banner-inseldorf.webp);
		}

		&.ausfluge {
			background-image: url(RESOURCE/img/banner-auslfuge.webp);
		}

		&.aufspiekeroog {
			background-image: url(RESOURCE/img/inner-banner-strand.webp);
		}

		&.versan {
			background-image: url(RESOURCE/img/inner-banner-konzert.webp);
		}
	}

	.unit-title {
		font-weight: bold;
	}


	.li-bullet {
		padding-left: 1.25rem;
		list-style: disc;
	}


}