@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-300.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-300.woff') format('woff');
	font-display: swap;
}

/* poppins-regular - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-regular.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-regular.woff') format('woff');
	font-display: swap;
}

/* poppins-500 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-500.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-500.woff') format('woff');
	font-display: swap;
}

/* poppins-600 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-600.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-600.woff') format('woff');
	font-display: swap;
}

/* poppins-700 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-700.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-700.woff') format('woff');
	font-display: swap;
}

/* barlow-semi-condensed-300 - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-300.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-300.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* barlow-semi-condensed-regular - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* barlow-semi-condensed-600 - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-600.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-600.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* barlow-semi-condensed-700 - latin */
@font-face {
	font-family: 'Barlow Semi Condensed';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-700.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/barlow-semi-condensed-v14-latin-700.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Playfair Display';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/playfair-display-v29-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/playfair-display-v29-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* parisienne-regular - latin-ext_latin */
@font-face {
	font-family: 'Parisienne';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/parisienne-v12-latin-ext_latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/parisienne-v12-latin-ext_latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Raleway*/
@font-face {
	font-family: 'Raleway';
	src: url('RESOURCE/fonts/Raleway-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Raleway-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url('RESOURCE/fonts/Raleway-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/Raleway-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Dancing Script';
	src: url('RESOURCE/fonts/DancingScript-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/DancingScript-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Dancing Script';
	src: url('RESOURCE/fonts/DancingScript-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/DancingScript-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

/*@font-face {
	font-family: 'Font Awesome 5 Free';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'FontAwesome';
	src: url('RESOURCE/fonts/fontawesome-webfont.eot?v=4.7.0');
	src: url('RESOURCE/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
		url('RESOURCE/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
		url('RESOURCE/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),
		url('RESOURCE/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
		url('RESOURCE/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	src: url('RESOURCE/fonts/FontAwesome5Pro-Regular.eot');
	src: url('RESOURCE/fonts/FontAwesome5Pro-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/FontAwesome5Pro-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/FontAwesome5Pro-Regular.woff') format('woff'),
		url('RESOURCE/fonts/FontAwesome5Pro-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/FontAwesome5Pro-Regular.svg#FontAwesome5Pro-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}*/