.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.unit-room-icons,
	.unit-room-bathroom,
	.unit-room-homeoffice {

		display: inline-block;
		min-width: 170px;
		border: 1px solid #ddddde;
		padding: 5px;
		margin: 0 5px 5px 0;
		min-height: 96px;

		i {
			width: 40px;
			height: 30px;
			display: inline-block;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}

		li {
			list-style: none;
		}

		.d-block {
			margin-bottom: 5px;
		}
	}

	.unit-roms-bl {
		display: flex;
		flex-wrap: wrap;
	}


	.icon-house-laptop {
		background-image: url(RESOURCE/img/icon-remote-work.png);
		width: 50px !important;
		height: 42px !important;
	}

	.icon-double-bed {
		background-image: url(RESOURCE/img/icon-double-bed.svg);
	}

	.icon-bathtub {
		background-image: url(RESOURCE/img/icon-bathtub.svg);
	}

	.icon-living-room {
		background-image: url(RESOURCE/img/icon-living-room.svg);
	}

	.icon-single-bed {
		background-image: url(RESOURCE/img/icon-single-bed-new.svg);
	}

	.icon-bulk-bed {
		background-image: url(RESOURCE/img/bed-bunk-light.svg);
	}

	.unit-object-box {
		display: flex;
		flex-wrap: wrap;

		.unit-feature {
			border-right: 1px solid #ddddde;
			margin: 0 16px 8px 0;
			display: block;
			position: relative;
			min-height: 24px;
			display: block;
			position: relative;
			color: #717171;
			line-height: 1.3;
			padding: 3px 20px 0 0;
			padding-left: 30px;

			@media (max-width:767px) {
				margin-right: 10px;
				padding-right: 10px;
			}

			&:last-child {
				border-right: none;
			}

			.fa {
				margin: 5px;
				position: absolute;
				left: 0;

			}
		}
	}

	.unit-title-v1 {
		@media(max-width:768px) {
			padding-top: 24px;
		}

		h1 {
			font-weight: 700;
			font-size: 2.25rem;
		}
	}

	.left-col {
		padding: 0px 15px;

		@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 1200px) {
			display: none;
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}


	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			display: none;
			margin-bottom: 57px;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}

	}

}