.quick-button {
	color: var(--color-white);
	background-color: var(--color-secondary);
	padding: 12px 60px 15px 20px;
	font-size: 1.6rem;
	width: 80px;
	height: 80px;
	position: fixed;
	right: -20px;
	bottom: 70px;
	z-index: 10;
	border-radius: 100% 0 0 100%;
	box-shadow: 0 2px 5px hsla(0, 0%, 0%, 0.2);
	text-align: center;
	transition: transform ease-out 100ms;
	cursor: pointer;
	border: none;

	&:hover {
		transform: translate(-20px);
	}

	@media (min-width:1200px) {
		bottom: 90px;
	}

	&.slided {
		right: 260px;
	}
}

.news-section {
	width: 300px;

	bottom: 2rem;
	display: none;
	font-family: 'Raleway', Helvetica, sans-serif;
	font-size: 1rem;
	color: hsl(112, 54%, 27%);
	background-color: #fff;
	border: 4px solid hsl(33, 100%, 60%);

	height: 200px;
	position: fixed;
	right: -20px;
	bottom: 1rem;
	z-index: 11;
	border-radius: 10px 0 0 10px;
	box-shadow: 0 2px 5px hsla(0, 0%, 0%, 0.2);
	text-align: center;
	transition: transform ease-out 100ms;
	cursor: pointer;
	margin: 0;
	padding: 0.75rem 2rem 0.75rem 0.75rem;
	overflow: hidden;

	p {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
	}

	a {
		color: hsl(33, 100%, 60%);
		font-weight: bold;
	}

	>div {
		margin-top: 15px;
		line-height: 1.2;

		h3 {
			font-size: 1rem;
		}
	}
}

.ft-block {
	background-color: var(--footer-bg-color);

	.newsletter-bl {

		padding: 30px 0;

		.btn {
			padding: 0.375rem 0.75rem;
		}

		.btm-bdr {
			border-bottom: 1px solid #dee2e6ab;
		}

	}


	.footer-v1 {

		.main-footer {

			background-color: var(--footer-bg-color);
			color: var(--footer-font-color);
			border-top: 1px solid var(--footer-border-top-color);

			.text-muted {
				color: var(--color-grey-normal) !important;

			}



			.first-col {
				@media (min-width: 1200px) {
					flex: 0 0 38.666667%;
					max-width: 38.666667%;
				}

				@media (max-width:767px) {
					margin-bottom: 3rem !important;
				}
			}

			.second-col {
				@media (min-width: 1200px) {
					flex: 0 0 28%;
					max-width: 28%;
				}
			}

			padding-left: 5vw;
			padding-right: 5vw;

			.headline {
				font-size: 20px;
				color: var(--color-white);
				text-transform: uppercase;
				font-family: var(--title-font-family-main);
			}


			.logo {
				width: 250px;
				height: auto;
				margin-top: -40px;
				padding-bottom: 20px;
			}

			.openings {

				div {
					padding-top: 10px;
				}
			}

			a {
				color: var(--footer-font-color);
			}

			.social-icons {

				li {

					a {
						color: var(--footer-font-color);
					}

					.fa {
						font-size: 20px;
						color: var(--footer-font-color);

					}
				}

			}




			#button-addon1 {
				color: #ffc371;
			}

			.btn-link {
				background-color: var(--footer-btn-bg-color);

				.fa {
					color: var(--footer-icon-color);
				}
			}

			.contact-links {
				li {
					margin: 0 0 0.5rem;


				}
			}

			i {
				color: var(--color-white);
				margin-right: 0.5rem;
			}

			.social-link {
				a {
					font-size: 2.25rem;
				}
			}

			.form-control::placeholder {
				font-size: 0.95rem;
				color: #aaa;
				font-style: italic;
			}

			.form-control.shadow-0:focus {
				box-shadow: none;
			}

			.paymentmethods {
				width: 100%;
				text-align: right;
				margin-top: 30px;

				.fa {
					color: var(--color-white);
					font-size: 2.3rem;
					padding-left: 10px;

				}
			}
		}

		.copyright {
			background-color: var(--footer-copyright-bg-color);
			color: var(--footer-copyright-font-color);
			font-size: 0.875rem;

			a {
				color: var(--footer-copyright-font-color);
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}

			.list-block {
				list-style: none;
				display: inline-block;

				li {
					list-style: none;
					display: inline-block;
					margin: 0 0 0.15rem 0.5rem;
				}
			}

			.v-office-logo {
				float: left;
				padding-right: 20px;

				img {
					width: 50px;
				}
			}
		}

	}
}