.parallax-v1 {
	position: relative;
	background-image: url(RESOURCE/img/parallax-bg.jpg);
	padding: 8rem 1rem;

	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	/*	&:before {
		background-color: hsla(112, 54%, 27%, 0.4);
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	}*/

	.title {
		color: var(--color-white);
		font-family: var(--hero-header-font-family);
		font-size: 3.25rem;
		line-height: 1.3;
	}

	big {
		line-height: 1.7;
		font-weight: normal;
		font-size: 1.5rem;
	}

	.parallax-box {
		background-color: var(--color-secondary);
		padding: 20px;
		min-height: 400px;
		position: relative;
		z-index: 1;


		.text {
			color: var(--color-white);
		}
	}

}